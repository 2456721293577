<template>
  <RevDrawer
    :close-button-label="i18n(dsTranslations.close)"
    :name
    :title="i18n(translations.modalName)"
    v-on="$attrs"
  >
    <template #body>
      <header class="-mt-24 flex flex-col">
        <RevIllustration
          alt="BackUp Header"
          class="mt-24 w-full"
          :height="150"
          src="/img/insurances/backupModalHeader.svg"
          :width="330"
        />
      </header>
      <section class="rounded-lg mt-24 border px-24">
        <table
          :aria-label="i18n(translations.modalName)"
          class="w-full table-fixed border-collapse border-0"
        >
          <tbody>
            <tr>
              <th class="w-1/4 py-20" />
              <th class="w-1/3 px-12 text-center">
                <RevIllustration
                  alt="BackUp logo"
                  class="align-middle"
                  :height="18"
                  src="/img/insurances/backUpLogo.svg"
                  :width="84"
                />
              </th>
              <th class="body-2 w-1/3 py-20 text-center">
                {{ i18n(translations.warranty) }}
              </th>
            </tr>

            <tr class="border-b">
              <td class="body-2-bold break-words py-8">
                {{ i18n(translations.comparisonTable1) }}
              </td>
              <td class="px-12 py-8">
                <p class="flex justify-center">
                  <IconCheckLarge class="text-static-success-hi" />
                </p>
              </td>
              <td class="py-8">
                <p class="flex justify-center">
                  <IconCheckLarge class="text-static-success-hi" />
                </p>
              </td>
            </tr>
            <tr class="border-b">
              <td class="body-2-bold break-words py-8">
                {{ i18n(translations.comparisonTable2) }}
              </td>
              <td class="px-12 py-8">
                <p class="flex justify-center">
                  <IconCheckLarge class="text-static-success-hi" />
                </p>
              </td>
              <td class="py-8">
                <p class="flex justify-center">
                  <IconCross class="text-static-danger-hi" />
                </p>
              </td>
            </tr>
            <tr v-if="shouldDisplayModalComparisonBenefit3" class="border-b">
              <td class="body-2-bold break-words py-8">
                {{ i18n(translations.comparisonTable3) }}
              </td>
              <td class="px-12 py-8">
                <p class="flex justify-center">
                  <IconCheckLarge class="text-static-success-hi" />
                </p>
              </td>
              <td class="py-8">
                <p class="flex justify-center">
                  <IconCross class="text-static-danger-hi" />
                </p>
              </td>
            </tr>
            <tr>
              <td class="body-2-bold break-words py-12">
                {{ i18n(translations.comparisonTable4) }}
              </td>
              <td class="p-12">
                <p class="flex justify-center">
                  <IconCheckLarge class="text-static-success-hi" />
                </p>
              </td>
              <td class="py-12">
                <p class="flex justify-center">
                  <IconCross class="text-static-danger-hi" />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section v-if="shouldDisplayGoodDeedInformation2" class="mt-24">
        <header>
          <h2 class="heading-2">
            {{ i18n(translations.goodDeedTitle) }}
          </h2>
          <p class="body-2 my-20">
            {{ i18n(translations.goodDeedBody1) }}
          </p>
          <p class="body-2">
            {{ i18n(translations.goodDeedBody2) }}
          </p>
        </header>
      </section>
      <section>
        <header class="my-24">
          <h2 class="heading-2">
            {{ i18n(translations.section3Title) }}
          </h2>
        </header>
        <RevAccordionList>
          <RevAccordionItem v-for="info in infos" :key="info.question">
            <template #title>
              {{ info.question }}
            </template>
            <template #body>
              {{ info.response }}
            </template>
          </RevAccordionItem>
        </RevAccordionList>
      </section>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { MarketCountryCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'
import { IconCheckLarge } from '@ds/icons/IconCheckLarge'
import { IconCross } from '@ds/icons/IconCross'
import { dsTranslations } from '@ds/translations'

import translations from './InsuranceModal.translations'

const GOOD_DEED_INFORMATION_2: Record<string, boolean> = {
  [MarketCountryCode.US]: false,
  [MarketCountryCode.GB]: false,
  [MarketCountryCode.JP]: false,
  default: true,
}

const COUNTRIES_WITHOUT_BENEFIT_3: Record<string, boolean> = {
  default: false,
}

defineProps<{ name: string }>()

const i18n = useI18n()
const country = useMarketplace().market.countryCode

const shouldDisplayGoodDeedInformation2 =
  GOOD_DEED_INFORMATION_2[country] ?? GOOD_DEED_INFORMATION_2.default

const shouldDisplayModalComparisonBenefit3 =
  COUNTRIES_WITHOUT_BENEFIT_3[country] ?? COUNTRIES_WITHOUT_BENEFIT_3.default

const infos = [
  {
    question: i18n(translations.infoQuestion1),
    response: i18n(translations.infoResponse1),
  },
  {
    question: i18n(translations.infoQuestion2),
    response: i18n(translations.infoResponse2),
  },
  {
    question: i18n(translations.infoQuestion3),
    response: i18n(translations.infoResponse3),
  },
]
</script>
