<template>
  <EnterViewportDetector
    v-if="renderChildren"
    :id="textToTarget(title, 'heading-2')"
    class="m-auto block size-full"
    @enter="trackBlockImpression"
  >
    <RevCardCarousel
      :next-alternative-text="i18n(translations.nextSlideText)"
      :prev-alternative-text="i18n(translations.prevSlideText)"
      :subtitle
      :title
    >
      <RevDialog
        v-for="(videoContent, index) in filteredVideos"
        :key="videoContent.id"
        :close-button-label="i18n(dsTranslations.close)"
        :name="videoContent.video.title"
        :title="videoContent.video.title"
        @close="pauseVideo(index, videoContent.id)"
      >
        <template #trigger>
          <RevButtonBase
            :aria-label="videoContent.video.title"
            class="rounded-lg relative cursor-pointer"
            @click="handleVideoClick(videoContent, index)"
            @focusin="playVideo(videoContent, index)"
            @focusout="pauseVideo(index, videoContent.id)"
            @mouseenter="playVideo(videoContent, index)"
            @mouseleave="pauseVideo(index, videoContent.id)"
          >
            <div
              v-if="!thumbnailsStatus[videoContent.id].playing"
              class="bg-action-default-low rounded-full absolute left-1/2 top-1/2 flex size-60 -translate-x-1/2 -translate-y-1/2 items-center justify-center"
            >
              <IconMediaPlay class="size-40" />
            </div>

            <div v-if="thumbnailsStatus[videoContent.id].shown">
              <RevIllustration
                v-if="videoContent.image?.src"
                v-bind="videoContent.image"
                class="rounded-lg"
              />
              <RevSkeleton
                v-else
                :data-test="`${videoContent.id}-skeleton`"
                :height="placeholderHeight"
                shape="rectangle"
              />
            </div>
            <VideoRenderer
              v-show="!thumbnailsStatus[videoContent.id].shown"
              ref="videoRefs"
              :data-test="videoContent.id"
              :thumbnail="videoContent.image"
              :tracking="trackingData(videoContent, index)"
              :video="videoContent.video.file"
            />
          </RevButtonBase>
        </template>
        <template #body>
          <VideoRenderer
            ref="videoRefs"
            class="mb-24"
            fullscreen
            :thumbnail="videoContent.image"
            :track-impression="false"
            :tracking
            :video="videoContent.video.file"
          />
        </template>
      </RevDialog>
    </RevCardCarousel>
  </EnterViewportDetector>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, ref } from 'vue'

import type {
  VideoContent,
  VideosBlockProps as VideosBlockApiProps,
} from '@backmarket/http-api/src/api-specs-content/models/videos-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevDialog } from '@ds/components/Dialog'
import { RevIllustration } from '@ds/components/Illustration'
import { openModal } from '@ds/components/ModalBase'
import { RevSkeleton } from '@ds/components/Skeleton'
import { IconMediaPlay } from '@ds/icons/IconMediaPlay'
import { dsTranslations } from '@ds/translations'

import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { textToTarget } from '@backmarket/nuxt-layer-cms/utils/textToTarget'

import EnterViewportDetector from '../../shared-components/EnterViewportDetector/EnterViewportDetector.vue'

import VideoRenderer from './VideoRenderer.vue'
import translations from './VideosBlock.translations'

export type VideosBlockProps = Required<ContentBlockProps> & VideosBlockApiProps

const props = withDefaults(defineProps<VideosBlockProps>(), {
  subtitle: '',
  title: '',
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { trackVideosBlockImpression, trackClick, trackHoverOver } = useTracking()
const i18n = useI18n()

const isPlaying = ref(false)
let focusTimeout: ReturnType<typeof window.setTimeout>

type VideoContentRequired = Omit<VideoContent, 'video'> & {
  video: NonNullable<VideoContent['video']>
}

// If the videos are in draft, they could be undefined
const filteredVideos = computed<VideoContentRequired[]>(() =>
  props.videos.filter(
    (videoContentFiltered): videoContentFiltered is VideoContentRequired =>
      !isEmpty(videoContentFiltered.video),
  ),
)

const trackingData = (video: VideoContentRequired, index: number) => ({
  ...props.tracking,
  position: index + 1,
  name: video.video.title,
})

const thumbnailsStatus = ref<
  Record<string, { shown: boolean; playing: boolean }>
>(
  filteredVideos.value.reduce(
    (acc, { id }) => ({ ...acc, [id]: { shown: true, playing: false } }),
    {},
  ),
)

const renderChildren = computed(() => filteredVideos.value.length > 0)

const trackBlockImpression = () => {
  trackVideosBlockImpression({
    ...props.tracking,
    blockPosition: props.blockPosition,
  })
}

const videoRefs = ref<InstanceType<typeof VideoRenderer>[]>([])

const placeholderHeight = computed(() => {
  const height = filteredVideos.value[0].image?.height || 455

  return `${height}px`
})
const getVideoFromIndex = (index: number) =>
  videoRefs.value[index].$el as HTMLVideoElement

const playVideo = ({ id, video }: VideoContentRequired, index: number) => {
  if (!isPlaying.value) {
    thumbnailsStatus.value[id] = { shown: false, playing: true }
    void getVideoFromIndex(index).play()
    isPlaying.value = true

    focusTimeout = setTimeout(() => {
      trackHoverOver({
        ...props.tracking,
        position: index + 1,
        name: video.title,
      })
    }, 2000)
  }
}

const pauseVideo = (index: number, id: string) => {
  if (isPlaying.value && typeof focusTimeout !== 'undefined') {
    getVideoFromIndex(index).pause()
    thumbnailsStatus.value[id].playing = false
    isPlaying.value = false
    clearTimeout(focusTimeout)
  }
}

const handleVideoClick = ({ video }: VideoContentRequired, index: number) => {
  openModal(video.title)
  trackClick({
    ...props.tracking,
    position: index,
    name: video.title,
  })
}

onBeforeUnmount(() => {
  if (typeof focusTimeout !== 'undefined') {
    clearTimeout(focusTimeout)
  }
})
</script>
